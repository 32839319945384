import React from 'react';
import styles from './SubtitleViewerLine.module.scss';
import { SubtitleLine } from '../../Redux/VideoPlayerReducer';

type Props = {
  subtitle: SubtitleLine;
  playSub: () => unknown;
};

const SubtitleViewerLine = (props: Props) => {
  const simplified = props.subtitle && props.subtitle.data.original;
  const pinyin = props.subtitle.data.words.map(a => a.pinyin || '').join('\n');
  //  const translated = props.subtitle.data.translated || '';

  return (
    <div className={styles.container} onClick={props.playSub}>
      <div className={styles.simplifiedText}>{simplified}</div>
      <div className={styles.pinyinText}>{pinyin}</div>
    </div>
  );
};

export default SubtitleViewerLine;
