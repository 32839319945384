import React, { PureComponent, useEffect, useState } from 'react';
import './VideoSubController.scss';
import { NavigateNext, NavigateBefore, Replay } from '@material-ui/icons';
import classNames from 'classnames';
import { useOnButtonPressed } from '../../../Hooks/useOnButtonPressed';
import { GamePadButton } from '../../../Utils/GamePadUtils';

type Props = {
  repeatSub: () => any;
  gotoNextSub: () => any;
  gotoPreviousSub: () => any;
};

let lastTimeout: number | null = null;
const VideoSubController = (props: Props) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseMove = () => {
    if (!isActive) {
      setIsActive(true);
    }
    clearTimeout(lastTimeout as any);
    lastTimeout = setTimeout(() => {
      setIsActive(false);
    }, 500) as any;
  };

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => window.removeEventListener('mousemove', onMouseMove);
  });

  useOnButtonPressed(GamePadButton.X, props.repeatSub);
  useOnButtonPressed(GamePadButton.RT, props.gotoNextSub);
  useOnButtonPressed(GamePadButton.LT, props.gotoPreviousSub);

  return (
    <div
      className={classNames('VideoSubController', {
        'VideoSubController--active': isActive
      })}
    >
      <NavigateBefore onClick={props.gotoPreviousSub} className="VideoSubController__button" width={26} height={26} />
      <Replay
        onClick={props.repeatSub}
        className="VideoSubController__button VideoSubController__button--replay"
        width={18}
        height={18}
      />
      <NavigateNext onClick={props.gotoNextSub} className="VideoSubController__button" width={26} height={26} />
    </div>
  );
};

export default VideoSubController;
