import * as React from 'react';
import './VideoSubs.scss';
import HoverWord from './HoverWord';
import classNames from 'classnames';
import { SubtitleLine } from '../Redux/VideoPlayerReducer';
import { useState } from 'react';
import useModal from 'use-react-modal';
import VocabSentenceDialog from './VocabSentenceDialog/VocabSentenceDialog';
import { useReduxState } from '../../../Utils/ReduxUtils';

type Props = {
  videoPath: string;
  subtitle?: SubtitleLine;
  onHover: () => any;
  onLeave: () => any;
};

function VideoSubs(props: Props) {
  const { showSimplified, showPinyin, showTranslation } = useReduxState(state => state.videoSettings);

  const { onHover, onLeave, subtitle } = props;
  const [hoveringId, setHovering] = useState<number | null>(null);
  const { isOpen, openModal, Modal, closeModal } = useModal({ background: 'rgba(0, 0, 0, 0.5)' } as any);

  const data = subtitle && subtitle.data;
  const height = 8 + (showSimplified ? 50 : 0) + (showPinyin ? 22 : 0) + (showTranslation ? 26 : 0);

  const openSentenceDialog = async (e: React.MouseEvent) => {
    openModal(e);
  };

  return (
    <div
      className={classNames('VideoSubs', {
        'VideoSubs--hidden': !data || (!showSimplified && !showPinyin && !showTranslation)
      })}
      onMouseEnter={onHover}
      onMouseLeave={() => {
        !isOpen && onLeave();
      }}
    >
      {isOpen && (
        <Modal>
          <VocabSentenceDialog subtitle={subtitle!} videoPath={props.videoPath} close={closeModal} />
        </Modal>
      )}

      <div className="VideoSubs__subLine" style={{ height }}>
        {(showSimplified || showPinyin) &&
          data &&
          data.words.map(word => (
            <HoverWord
              onHover={() => {
                setHovering(word.id);
              }}
              isHovering={word.id === hoveringId}
              key={word.id}
              showSimplified={showSimplified}
              showPinyin={showPinyin}
              simplified={word.simplified}
              pinyin={word.pinyin || ''}
              definition={word.def || ''}
            />
          ))}
        {showTranslation && data && <div className="VideoSubs_translated">{data.translated}</div>}
      </div>
      <div onClick={openSentenceDialog} className="VideoSubs__subLineBtn">
        Save Sentence
      </div>
    </div>
  );
}

export default VideoSubs;
