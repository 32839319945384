import { Guid } from 'guid-typescript';

import front from './templates/anki-front';
import back from './templates/anki-front';
import styling from './templates/anki-front';
import { convertBlobToBase64 } from '../../../../Utils/AudioExtractor';

const ankiServer = 'http://127.0.0.1:8765';
const version = 6;

const ankiInvoke = async <T>(action: string, params = {}): Promise<T> => {
  try {
    const res = await fetch(ankiServer, {
      method: 'POST',
      body: JSON.stringify({ action, version, params })
    });
    const ans = await res.json();
    if (ans.error) {
      // noinspection ExceptionCaughtLocallyJS
      throw ans.error;
    }

    return ans.result;
  } catch (e) {
    // we just want to ignore this error
    if (e === 'Model name already exists') {
      return '' as any;
    }
    alert('Could not connect to Anki. Make sure anki-connect is installed and anki is running');
    throw e;
  }
};

export const getDecks = async () => {
  return await ankiInvoke<string[]>('deckNames');
};

export const createDeck = async (deckName: string) => {
  // returns deck id
  return await ankiInvoke<number>('createDeck', { deck: deckName });
};

export const getModelNames = async () => {
  return await ankiInvoke<string[]>('modelNames');
};

export const createModel = async (modelName: string) => {
  return await ankiInvoke<any>('createModel', {
    modelName: modelName,
    inOrderFields: ['simplified', 'pinyin', 'meaning', 'audio'],
    css: styling,
    cardTemplates: [
      {
        Front: front,
        Back: back
      }
    ]
  });
};

export const getModelTemplates = async (modelName: string) => {
  return await ankiInvoke<{ [card: string]: { [feild: string]: string } }[]>('modelTemplates', { modelName });
};

export const createNote = async (params: {
  deckName: string;
  modelName: string;
  fields: {
    simplified: string;
    pinyin: string;
    meaning: string;
    audio: string;
  };
}) => {
  return await ankiInvoke<any>('addNote', {
    note: {
      ...params,
      tags: []
    }
  });
};

export const storeMediaFile = async (file: Blob) => {
  const filename = `subtitlehero-${Guid.create()}.ogg`;
  const dataBase64 = await convertBlobToBase64(file);
  await ankiInvoke('storeMediaFile', {
    filename,
    data: dataBase64.replace('data:audio/ogg; codecs=opus;base64,', '')
  });
  return `[sound:${filename}]`;
};
