import * as React from 'react';
import './VideoPlayerPage.scss';
import VideoPlayer from './VideoPlayer';
import HeroLoader from '../../Components/HeroLoader/HeroLoader';
import { useReduxDispatch, useReduxState } from '../../Utils/ReduxUtils';
import { useEffect, useState } from 'react';
import { addSubtitles } from './Redux/VideoPlayerActions';
import Helmet from 'react-helmet';
import { isMobile } from '../../Utils/GeneralUtils';

type Props = {
  videoUrl: string;
  subtitleUrl: string;
  autoPlay?: boolean;
  fileName: string;
};

const VideoPlayerPage = (props: Props) => {
  const [loaded, setLoading] = useState(false);
  const dispatch = useReduxDispatch();
  const subtitles = useReduxState(state => state.videoPlayer.subtitles);

  useEffect(() => {
    const fn = async () => {
      await dispatch(addSubtitles(props.subtitleUrl));
      if (!isMobile) {
        await fetch(props.videoUrl);
      }
      setLoading(true);
    };
    fn();
  }, [dispatch, props.subtitleUrl, props.videoUrl]);

  const goBack = () => {
    window.location.href = '/';
  };

  return (
    <div className="VideoPlayerPage">
      <Helmet>
        <style>{'body { background-color: black!important; }'}</style>
      </Helmet>

      <VideoPlayer
        timeStorageKey={props.fileName}
        videoPath={props.videoUrl}
        subtitles={subtitles!}
        goBack={goBack}
        seekProgress={100}
        autoPlay={props.autoPlay}
      />

      {!loaded && <HeroLoader />}
    </div>
  );
};

export default VideoPlayerPage;
