export const hasPlugin = !!(
  (window as any).chrome &&
  (window as any).chrome.runtime &&
  (window as any).chrome.runtime.sendMessage
);
export const vocabPluginId = 'egbhlijnkoompngemjibfdfpchopglaf';

let fileId = 1;
export const addWordToPlugin = async (word: string, pinyin: string, definition: string, audioFile?: Blob) => {
  let curFileId = fileId++;

  if (audioFile) {
    await sendBlobToPlugin(word, audioFile, 'audio/ogg; codecs=opus', curFileId);
  }

  (window as any).chrome.runtime.sendMessage(
    vocabPluginId,
    { word, pinyin, definition, fileId: audioFile ? curFileId : undefined },
    (response: any) => {
      console.log(response);
    }
  );
};

export const sendBlobToPlugin = (word: string, blob: Blob, mimeString: string, fileId: number) => {
  return new Promise((resolve, reject) => {
    // read the blob in chunks/chunks and send it to the app
    // Note: I crashed the app using 1 KB chunks. 1 MB chunks work just fine.
    // I decided to use 256 KB as that seems neither too big nor too small
    const CHUNK_SIZE = 256 * 1024;
    let start = 0;
    let stop = CHUNK_SIZE;

    let remainder = blob.size % CHUNK_SIZE;
    let chunks = Math.floor(blob.size / CHUNK_SIZE);
    let chunkIndex = 0;
    if (remainder !== 0) chunks = chunks + 1;

    const fr = new FileReader();
    const processChunk = () => {
      chunkIndex++;
      // exit if there are no more chunks
      if (chunkIndex > chunks) {
        resolve();
        return;
      }

      if (chunkIndex === chunks && remainder !== 0) {
        stop = start + remainder;
      }

      let blobChunk = blob.slice(start, stop);

      // prepare for next chunk
      start = stop;
      stop = stop + CHUNK_SIZE;

      // convert chunk as binary string
      fr.readAsBinaryString(blobChunk);
    };

    fr.onload = function() {
      const message = {
        fileId,
        blobAsText: fr.result,
        mimeString: mimeString,
        chunks: chunks
      };
      // APP_ID was obtained elsewhere
      (window as any).chrome.runtime.sendMessage(vocabPluginId, message, () => {
        if ((window as any).runtime.lastError) {
          alert('could not send message to app');
          reject();
        }
      });
      processChunk();
    };

    fr.onerror = () => {
      alert('An error ocurred while reading file');
      reject();
    };

    processChunk();
  });
};

let deferredPrompt: any = null;
window.addEventListener('beforeinstallprompt', (e: any) => {
  e.preventDefault();
  deferredPrompt = e;
});

export const installApp = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
  } else {
    alert('Unable to install as App');
  }
};
