export const getTimeDivided = (timeInS: number) => {
  let hours: number = Math.floor(timeInS / 3600);
  let leftOverMins: number = timeInS % 3600;
  let minutes: number = Math.floor(leftOverMins / 60);
  let seconds: number = Math.ceil(leftOverMins % 60);

  return { hours, minutes, seconds };
};

export const padZeros = (num: number, length: number) => {
  return leftPad(num, '0', length);
};

export const leftPad = (str: string | number, padString: string, length: number) => {
  if (typeof str === 'number') str = str + '';
  while (str.length < length) str = padString + str;
  return str;
};
