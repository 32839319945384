import { useParams } from 'react-router';
import VideoPlayerPage from './VideoPlayer/VideoPlayerPage';
import React from 'react';

const VideoPlayerSharePage = (props: {}) => {
  let { movieName } = useParams();
  return (
    <VideoPlayerPage
      videoUrl={`/share/${movieName}.mp4`}
      subtitleUrl={`/share/${movieName}.srt`}
      fileName={movieName!}
    />
  );
};

export default VideoPlayerSharePage;
