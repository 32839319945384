import * as React from 'react';
import './VideoPlayer.scss';
import VideoSubs from './components/VideoSubs';
import VideoControls from './components/VideoControls/VideoControls';
import VideoSubController from './components/VideoSubController';
import { SubtitleLine } from './Redux/VideoPlayerReducer';
import { ReducerState } from '../../Redux/RootReducer';
import { connect } from 'react-redux';
import { updateSubtitle } from './Redux/VideoPlayerActions';
import VideoBlackBar from './components/VideoBlackBar';

type Props = {
  videoPath: string;
  seekProgress: number;
  // subtitles need to be ordered
  subtitles: SubtitleLine[];
  goBack: () => any;
  autoPlay?: boolean;
  timeStorageKey: string;

  updateSubtitle: typeof updateSubtitle;
  currentSub: number;
  subHidden: boolean;
};

class VideoPlayer extends React.PureComponent<Props> {
  state = {
    shouldPlay: false,
    currentTime: 0,
    repeatingSub: -1,

    showSimplified: true,
    showPinyin: true,
    showTranslated: false
  };

  videoElement = React.createRef<HTMLVideoElement>();
  updateDimensions = () => {
    this.forceUpdate();
  };

  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillMount(): void {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateLocalStorageTime = async (time: number) => {
    const { timeStorageKey } = this.props;
    localStorage.setItem(timeStorageKey || 'videoPlayerTime', time.toString());
  };

  onTimeChange = (t: any) => {
    const currentTime = t.target.currentTime;
    // noinspection JSIgnoredPromiseFromCall
    this.updateLocalStorageTime(currentTime);
    const { currentSub } = this.props;

    if (this.state.repeatingSub >= 0) {
      const sub = this.props.subtitles[this.state.repeatingSub];
      if (currentTime + 0.2 > sub.end + 0.1) {
        this.videoElement.current!.currentTime = currentTime - 0.3;
        this.videoElement.current!.pause();
        this.setState({ currentTime: currentTime - 0.3, repeatingSub: -1 });
      } else {
        this.setState({ currentTime });
      }
      return;
    }

    this.props.updateSubtitle(currentTime, currentSub >= 0 ? currentSub : 0);
    this.setState({ currentTime });
  };

  onSeek = (currentTime: number) => {
    this.setState({ repeatingSub: -1, currentTime });
    this.videoElement.current!.currentTime = currentTime;
    this.props.updateSubtitle(currentTime);
  };

  // changing currentTime causes it to be called again. Creating a loop
  firstOnCanPlayCall = true;
  onCanPlay = () => {
    if (this.firstOnCanPlayCall) {
      this.firstOnCanPlayCall = false;
      const { timeStorageKey } = this.props;
      const startFromTime = Number(localStorage.getItem(timeStorageKey || 'videoPlayerTime'));
      if (startFromTime) {
        this.videoElement.current!.currentTime = startFromTime;
        this.props.updateSubtitle(startFromTime);
      }
    }
  };

  pauseVideo = () => {
    if (!this.videoElement.current) return;
    if (!this.videoElement.current.paused) {
      this.videoElement.current.pause();
      this.setState({ shouldPlay: true });
    }
  };

  playVideo = () => {
    if (!this.videoElement) return;
    if (this.state.shouldPlay || this.state.repeatingSub) {
      this.videoElement.current!.play();
      this.setState({ shouldPlay: false });
    }
  };

  togglePlay = () => {
    if (this.videoElement.current!.paused) {
      this.videoElement.current!.play();
    } else {
      this.videoElement.current!.pause();
    }
  };

  gotoNextSub = () => {
    let { subtitles, currentSub } = this.props;
    if (currentSub + 1 >= subtitles.length) {
      return;
    }
    const nextSub = currentSub >= 0 ? currentSub + 1 : 0;
    this.onSeek(subtitles[nextSub].start);
  };

  gotoPreviousSub = () => {
    const { subtitles, currentSub, subHidden } = this.props;
    if (currentSub === -1 || subtitles.length === 0) {
      return;
    }

    if (currentSub === -2) {
      this.onSeek(subtitles[subtitles.length - 1].start);
      return;
    }

    const nextIndex = currentSub + (subHidden ? 0 : -1);
    this.onSeek(subtitles[nextIndex >= 0 ? nextIndex : 0].start);
  };

  repeatSub = (repeatingSub: number | null) => {
    const { subtitles, currentSub } = this.props;
    repeatingSub = repeatingSub === null ? currentSub : repeatingSub;
    if (repeatingSub < 0) {
      return;
    }
    const currentTime = subtitles[repeatingSub].start - 0.2;
    this.setState({ repeatingSub, currentTime });
    this.props.updateSubtitle(currentTime + 0.3);
    this.videoElement.current!.currentTime = currentTime;
    this.videoElement.current!.play();
  };

  getVideoBottom = (): number => {
    if (!this.videoElement || !this.videoElement.current) return 0;
    const vWidth = this.videoElement.current!.videoWidth;
    const vHeight = this.videoElement.current!.videoHeight;

    const wWidth = document.body.clientWidth;
    const wHeight = document.body.clientHeight;

    const widthRatio = wWidth / vWidth;
    const heightRatio = wHeight / vHeight;

    if (widthRatio > heightRatio) return 0;
    return (wHeight - widthRatio * vHeight) / 2;
  };

  getVideoHeight = (videoBottom: number): number => {
    const wHeight = document.body.clientHeight;
    return wHeight - videoBottom * 2;
  };

  render() {
    const { videoPath, goBack, subtitles, seekProgress, autoPlay, subHidden, currentSub } = this.props;
    const { currentTime } = this.state;
    const { showSimplified, showPinyin, showTranslated } = this.state;
    const video = this.videoElement.current;

    const isPlaying = !!(video && !video.paused);

    const subtitle = !subHidden && (subtitles[currentSub] as any);
    const videoBottom = this.getVideoBottom();
    const videoHeight = this.getVideoHeight(videoBottom);

    return (
      <div className="VideoPlayer">
        <video
          className="VideoPlayer__video"
          autoPlay={autoPlay}
          onClick={this.togglePlay}
          onLoadedMetadata={this.updateDimensions}
          onCanPlay={this.onCanPlay}
          ref={this.videoElement}
          onTimeUpdate={this.onTimeChange}
          playsInline={true}
        >
          <source src={videoPath} type="video/mp4" />
        </video>

        {video && (
          <>
            <div
              className="VideoPlayer__subs"
              style={{
                bottom: videoBottom
              }}
            >
              <VideoBlackBar height={videoHeight} />

              <VideoSubController
                repeatSub={() => this.repeatSub(null)}
                gotoNextSub={this.gotoNextSub}
                gotoPreviousSub={this.gotoPreviousSub}
              />

              <VideoSubs videoPath={videoPath} subtitle={subtitle} onHover={this.pauseVideo} onLeave={this.playVideo} />
            </div>

            <VideoControls
              videoPath={videoPath}
              subtitles={subtitles}
              video={this.videoElement}
              isPlaying={isPlaying}
              currentTime={currentTime}
              duration={(this.videoElement.current && this.videoElement.current.duration) || 0}
              seekProgress={seekProgress}
              seek={this.onSeek}
              togglePlay={this.togglePlay}
              goBack={goBack}
              playSub={this.repeatSub}
            />
          </>
        )}
      </div>
    );
  }
}

export default connect(
  (state: ReducerState) => ({
    subHidden: state.videoPlayer.subHidden,
    currentSub: state.videoPlayer.currentSub
  }),
  {
    updateSubtitle
  }
)(VideoPlayer as any) as any;
