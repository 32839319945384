import React, { useEffect, useState } from 'react';
import styles from './VocabSentenceDialog.module.scss';
import { SubtitleLine } from '../../Redux/VideoPlayerReducer';
import { convertBlobToBase64, extraAudio } from '../../../../Utils/AudioExtractor';
import { addWordToPlugin } from '../../../../Utils/ChromeUtils';
import { createNote, storeMediaFile } from '../AnkiDialog/AnkiUtils';
import { Exit } from '../PlayerIcons';
import classNames from 'classnames';
import { useReduxState } from '../../../../Utils/ReduxUtils';

type Props = {
  subtitle: SubtitleLine;
  videoPath: string;
  close: () => unknown;
};

const VocabSentenceDialog = (props: Props) => {
  const settings = useReduxState(state => state.videoSettings);
  const [savedVH, setSavedVH] = useState(false);
  const [savedAnki, setSavedAnki] = useState(false);

  const [isProcessing, setProcessing] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [audioBlob, setAudioBlob] = useState<Blob | undefined>(undefined);
  const simplified = props.subtitle && props.subtitle.data.original;
  const pinyin = props.subtitle.data.words.map(a => a.pinyin || '').join('\n');
  const translated = props.subtitle.data.translated || '';

  const generateAudio = async () => {
    if (isProcessing) return;
    setProcessing(true);
    const { blob, url: fileUrl } = await extraAudio(props.videoPath, props.subtitle.start, props.subtitle.end);
    setAudioUrl(fileUrl);
    setAudioBlob(blob);
    setProcessing(false);
  };

  useEffect(() => {
    generateAudio();
    // eslint-disable-next-line
  }, []);

  const saveToVocabHero = async () => {
    if (savedVH) return;
    setSavedVH(true);
    addWordToPlugin(simplified, pinyin, translated, audioBlob);
  };

  const saveToAnki = async () => {
    if (savedAnki) return;
    setSavedAnki(true);
    try {
      const audio = await storeMediaFile(audioBlob!);
      await createNote({
        deckName: 'Subtitle Hero',
        modelName: 'Subtitle Hero Hanzi Question',
        fields: {
          simplified: simplified,
          pinyin: pinyin,
          meaning: translated || '',
          audio
        }
      });
    } catch (e) {
      setSavedAnki(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>Save Sentence</div>
        <div className={styles.exitButton} onClick={props.close}>
          <Exit height={20} width={20} />
        </div>
      </div>

      <div className={styles.simplifiedText}>{simplified}</div>
      <div className={styles.pinyinText}>{pinyin}</div>
      <div className={styles.translatedText}>{translated}</div>

      {isProcessing && <div>Extracting Audio...</div>}
      {!isProcessing && <audio controls={true} src={audioUrl} />}

      <div className={styles.buttons}>
        <button
          onClick={saveToVocabHero}
          className={classNames(styles.button, {
            [styles.button__saved]: savedVH
          })}
        >
          {savedVH ? 'Saved To Vocab Hero' : 'Save To Vocab Hero'}
        </button>
        {settings.isAnkiSetup && (
          <button
            onClick={saveToAnki}
            className={classNames(styles.button, {
              [styles.button__saved]: savedAnki
            })}
          >
            {savedAnki ? 'Anki Card Created' : 'Create Anki Card'}
          </button>
        )}
      </div>
    </div>
  );
};

export default VocabSentenceDialog;
