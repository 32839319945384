import { VideoSettingsActions, VideoSettingsActionTypes } from './VideoSettingsTypes';
import { getSavedSettings } from '../../../../Utils/StorageUtils';

export interface VideoSettingsReducerState {
  blackBarHeight: number;

  showSimplified: boolean;
  showPinyin: boolean;
  showTranslation: boolean;

  isAnkiSetup: boolean;
}

const savedSetting = getSavedSettings();

const initialState: VideoSettingsReducerState = {
  blackBarHeight: 0,

  showSimplified: true,
  showPinyin: true,
  showTranslation: false,

  isAnkiSetup: false,

  ...savedSetting
};

export default (
  state: VideoSettingsReducerState = initialState,
  action: VideoSettingsActions
): VideoSettingsReducerState => {
  switch (action.type) {
    case VideoSettingsActionTypes.CLEAR: {
      return { ...initialState };
    }

    case VideoSettingsActionTypes.UPDATE_SETTING: {
      const { setting, value } = action.payload;
      return { ...state, [setting]: value };
    }
    default: {
      return state;
    }
  }
};
