import React from 'react';
import styles from './VideoBlackBar.module.scss';
import { useReduxState } from '../../../Utils/ReduxUtils';

type Props = {
  height: number;
};

const VideoBlackBar = (props: Props) => {
  const settings = useReduxState(state => state.videoSettings);
  if (!settings.blackBarHeight) return null;
  return <div className={styles.container} style={{ height: props.height * (settings.blackBarHeight / 100) }} />;
};

export default VideoBlackBar;
