import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import rootReducer from './Redux/RootReducer';
import thunk from 'redux-thunk';

import { prepareReduxStore } from './Utils/ReduxUtils';

import './Assets/rangeslider.scss';
import './Assets/video-seek-slider.scss';
import { isMobile } from './Utils/GeneralUtils';
import { setupGamePadListeners } from './Utils/GamePadUtils';

const store = prepareReduxStore({
  reducers: rootReducer,
  middlewares: [thunk]
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (isMobile) {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}

setupGamePadListeners();
