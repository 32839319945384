import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import VideoPlayerPage from './Pages/VideoPlayer/VideoPlayerPage';
import SimpleUploader from './Pages/SimpleUploader';

import demoVideo from './Assets/demo-clip.mp4';
import demoSrtUrl from './Assets/generated.srt';

import MoviePage from './Pages/MoviePage/MoviePage';
import VideoPlayerSharePage from './Pages/VideoPlayerSharePage';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/demo">
            <VideoPlayerPage videoUrl={demoVideo} subtitleUrl={demoSrtUrl} fileName="demoVideo" />
          </Route>

          <Route path="/share/:movieName">
            <VideoPlayerSharePage />
          </Route>

          <Route path="/movie/:imdbId/:fileName/:movieName">
            <MoviePage />
          </Route>

          <Route path="/">
            <SimpleUploader />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
