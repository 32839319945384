import * as React from 'react';
import { RefObject, useState } from 'react';
import './VideoControls.scss';
import * as PlayerIcons from '../PlayerIcons';
import { ArrowBackIos, Fullscreen, Notes, LibraryBooks } from '@material-ui/icons';
import VolumeControls from './VolumeControls';
import { toggleFullScreen } from '../../../../Utils/FullScreenUtils';
import { SubtitleLine } from '../../Redux/VideoPlayerReducer';
import VideoSeeker from '../VideoSeeker/VideoSeeker';
import VideoSettings from '../VideoSettings/VideoSettings';
import { useOnButtonPressed } from '../../../../Hooks/useOnButtonPressed';
import { GamePadButton } from '../../../../Utils/GamePadUtils';
import SubtitleViewer from '../SubtitleViewer/SubtitleViewer';

type Props = {
  videoPath: string;
  subtitles: SubtitleLine[];
  video: RefObject<HTMLVideoElement>;
  isPlaying?: boolean;
  currentTime: number;
  goBack: () => any;
  togglePlay: () => any;

  seekProgress?: number;
  seek: (time: number) => any;
  duration: number;

  playSub: (index: number) => unknown;
};

const VideoControls = (props: Props) => {
  const [{ previousVolume, volume }, setVolume] = useState({ previousVolume: null as null | number, volume: 100 });
  const [settingPanelOpen, setSettingPanelOpen] = useState(false);
  const [subViewerActive, setSubViewerActive] = useState(false);

  const { isPlaying, currentTime, seek, duration, goBack, togglePlay, videoPath } = props;

  const onUpdateVolume = (volume: number) => {
    const { video } = props;
    video.current!.volume = volume / 100;
    setVolume({ previousVolume, volume });
  };

  const onMute = (mute: boolean) => {
    const { video } = props;
    if (mute) {
      video.current!.volume = 0;
      setVolume({ previousVolume: volume, volume: 0 });
    } else {
      video.current!.volume = (previousVolume || 0) / 100;
      setVolume({ previousVolume, volume: previousVolume! });
    }
  };

  useOnButtonPressed(GamePadButton.Y, togglePlay);

  //

  return (
    <>
      <SubtitleViewer active={subViewerActive} exit={() => setSubViewerActive(false)} playSub={props.playSub} />
      <VideoSettings active={settingPanelOpen} exit={() => setSettingPanelOpen(false)} />

      <div className="VideoControls">
        <ArrowBackIos className="VideoControls__BackBtn" onClick={goBack} width={26} height={26} />

        <Notes
          className="VideoControls__openSubPanel"
          onClick={() => setSettingPanelOpen(true)}
          width={26}
          height={26}
        />

        <LibraryBooks
          className="VideoControls__openSubtitleViewerBtn"
          onClick={() => setSubViewerActive(true)}
          width={26}
          height={26}
        />

        <div className="VideoControls__lbHitbox" />
        <div className="VideoControls__rbHitbox" />

        <div className="VideoControls__seekerBottom">
          <div className="VideoControls__seekerBg" />
          <div className="VideoControls__seeker">
            <VideoSeeker
              videoPath={videoPath}
              onChange={seek}
              duration={duration}
              currentTime={currentTime}
              isPlaying={isPlaying}
            />
          </div>

          <div className="VideoControls__buttons">
            <div className="VideoControls__buttonsLeft" onClick={togglePlay}>
              {isPlaying ? <PlayerIcons.Pause width={20} height={20} /> : <PlayerIcons.Play width={20} height={20} />}
            </div>
            <div className="VideoControls__buttonsRight">
              <VolumeControls volume={volume} onMute={onMute} onUpdateVolume={onUpdateVolume} />
              <div className="VideoControls__fullScreen">
                <Fullscreen onClick={toggleFullScreen} width={26} height={26} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoControls;
