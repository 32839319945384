import React, { useEffect, useState } from 'react';
import styles from './VideoReviewPage.module.scss';
import { addSubtitles } from '../VideoPlayer/Redux/VideoPlayerActions';
import { useReduxDispatch, useReduxState } from '../../Utils/ReduxUtils';
import { SubtitleLine } from '../VideoPlayer/Redux/VideoPlayerReducer';
import DataTable from 'react-data-table-component';

type Props = {
  videoUrl: string;
  subtitleUrl: string;
};

const getAllWords = (sentences: SubtitleLine[]): { word: string; movieFreq: number }[] => {
  const seenWords: { [word: string]: number } = {};
  sentences.forEach(sentence => {
    sentence.data.words.forEach(word => {
      if (word.def) {
        seenWords[word.simplified] = (seenWords[word.simplified] || 0) + 1;
      }
    });
  });
  return Object.keys(seenWords).map(word => ({ word, movieFreq: seenWords[word] }));
};

function getJsonFile<T>(url: string): Promise<T> {
  return fetch(url).then(response => {
    return response.json();
  });
}
type WordDef = { word: string; reading: string; meaning: string; freq: number; hsk: number; movieFreq?: number };

const addWordInfo = async (words: { word: string; movieFreq: number }[]) => {
  const data = await getJsonFile<{ [key: string]: WordDef }>('/wordDict.json');
  const defs = words.filter(word => data[word.word]).map(word => ({ ...data[word.word], movieFreq: word.movieFreq }));

  return defs.sort((a, b) => {
    if (a.hsk !== b.hsk) {
      return a.hsk - b.hsk;
    } else {
      return a.freq - b.freq;
    }
  });
};

const columns = [
  {
    name: 'Word',
    selector: 'word',
    sortable: true,
    grow: 0
  },
  {
    name: 'Reading',
    selector: 'reading',
    sortable: true,
    grow: 1
  },
  {
    name: 'Hsk',
    selector: 'hsk',
    sortable: true,
    right: true,
    grow: 0
  },
  {
    name: 'Movie Frequency',
    selector: 'movieFreq',
    sortable: true,
    right: true,
    grow: 0
  },
  {
    name: 'Corpus Frequency',
    selector: 'freq',
    sortable: true,
    right: true,
    grow: 0
  },
  {
    name: 'Meaning',
    selector: 'meaning',
    grow: 10
  }
];

const VideoReviewPage = (props: Props) => {
  const [loaded, setLoading] = useState(true);
  const dispatch = useReduxDispatch();
  const subtitles = useReduxState(state => state.videoPlayer.subtitles);
  const [wordList, setWordList] = useState(null as WordDef[] | null);

  useEffect(() => {
    const fn = async () => {
      await dispatch(addSubtitles(props.subtitleUrl));
      await fetch(props.videoUrl);
      setLoading(true);
    };
    fn();
  }, [dispatch, props.subtitleUrl, props.videoUrl]);

  useEffect(() => {
    const fn = async () => {
      const words = getAllWords(subtitles!);
      const wordsWithDef = await addWordInfo(words);
      setWordList(wordsWithDef);
    };
    if (subtitles) {
      fn();
    }
  }, [subtitles]);

  const removeEasyWords = () => {
    if (wordList) {
      const newWordList = wordList.filter(a => a.hsk > 3 || (a.hsk === 0 && a.freq < 1000));
      setWordList(newWordList);
    }
  };

  return (
    <div className={styles.container}>
      {loaded && wordList && (
        <>
          <div>Total Word Countr {wordList.length}</div>
          <div onClick={removeEasyWords}>Remove Easy Words</div>
          <DataTable title="Words" columns={columns} data={wordList} />
        </>
      )}
    </div>
  );
};

export default VideoReviewPage;
