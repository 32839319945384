import React, { useState } from 'react';
import styles from './MoviePage.module.scss';
import Helmet from 'react-helmet';
import VideoPlayerPage from '../VideoPlayer/VideoPlayerPage';
import { useParams } from 'react-router-dom';

type Props = {};

const MoviePage = (props: Props) => {
  let { imdbId, fileName, movieName } = useParams();
  const [videoFile, setVideoFile] = useState<null | string>(null);

  const onSelectMovie = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0]!;
    const url = URL.createObjectURL(file);
    setVideoFile(url);
  };

  if (videoFile) {
    return (
      <VideoPlayerPage
        videoUrl={videoFile}
        subtitleUrl={`/subtitles/${fileName}.srt`}
        autoPlay={true}
        fileName={movieName!}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <style>{'body { background-color: #1D252C!important; }'}</style>
      </Helmet>
      <img className={styles.bg} src={`/subtitles/${fileName}.jpg`} />

      <div className={styles.modal}>
        <p>
          To Watch "
          <a target="_blank" href={`https://www.imdb.com/title/${imdbId}/`}>
            {movieName}
          </a>
          " with SubtitleHero you need to already have the video downloaded as an mp4/mkv to your local hard drive.
        </p>

        <input className={styles.fileInput} id="videoFile" type="file" accept="video/*" onChange={onSelectMovie} />
        <label className={styles.selectBtn} htmlFor="videoFile">
          Select from your hard drive
        </label>
      </div>
    </div>
  );
};

export default MoviePage;
