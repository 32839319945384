import { useEffect, useRef } from 'react';
import { GamePadButton, onButtonPressed } from '../Utils/GamePadUtils';

export function useOnButtonPressed(button: GamePadButton, callback: () => any) {
  const savedCallback = useRef<() => any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const handler = () => savedCallback.current!();
    const removeHandler = onButtonPressed(buttonId => {
      if (buttonId === button) {
        handler();
      }
    });

    return () => removeHandler();
  }, []);
}
