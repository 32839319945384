import { combineReducers } from 'redux';
import videoPlayer, { VideoPlayerReducerState } from '../Pages/VideoPlayer/Redux/VideoPlayerReducer';
import videoSettings, {
  VideoSettingsReducerState
} from '../Pages/VideoPlayer/Redux/VideoSettings/VideoSettingsReducer';

export default combineReducers({
  videoPlayer,
  videoSettings
});

export interface ReducerState {
  videoPlayer: VideoPlayerReducerState;
  videoSettings: VideoSettingsReducerState;
}
