const EventEmitter = require('events');

const emitter = new EventEmitter();
const activeGamepads: { [gamePadIndex: number]: any } = {};
const heldButtons: { [gamePadIndex: number]: any } = {};
export enum GamePadButton {
  A,
  X,
  B,
  Y,
  LT,
  RT,
  ZR,
  R,
  Plus,
  JoyClick
}
const buttonMapping: { [buttonIndex: number]: GamePadButton } = {
  0: GamePadButton.A,
  1: GamePadButton.X,
  2: GamePadButton.B,
  3: GamePadButton.Y,
  4: GamePadButton.LT,
  5: GamePadButton.RT,
  7: GamePadButton.ZR,
  8: GamePadButton.R,
  9: GamePadButton.Plus,
  10: GamePadButton.JoyClick
};

export const setupGamePadListeners = () => {
  window.addEventListener('gamepadconnected', (e: any) => {
    addGamepad(e.gamepad);
  });
  window.addEventListener('gamepaddisconnected', (e: any) => {
    removeGamepad(e.gamepad);
  });
};

function addGamepad(gamepad: any) {
  activeGamepads[gamepad.index] = gamepad;
  heldButtons[gamepad.index] = {};
  window.requestAnimationFrame(updateStatus);
}

function removeGamepad(gamepad: any) {
  delete activeGamepads[gamepad.index];
  delete heldButtons[gamepad.index];
}

function updateStatus() {
  scanGamepads();
  let seenGamepad = false;

  for (let gIdx in activeGamepads) {
    const curGamepad = activeGamepads[gIdx];
    seenGamepad = true;

    for (let bIdx = 0; bIdx < curGamepad.buttons.length; bIdx++) {
      const button = curGamepad.buttons[bIdx];

      if (typeof button == 'object') {
        if (button.pressed) {
          if (!heldButtons[gIdx][bIdx]) {
            heldButtons[gIdx][bIdx] = true;
            triggerButtonEvent(curGamepad.id, bIdx);
          }
        } else if (heldButtons[gIdx][bIdx]) {
          heldButtons[gIdx][bIdx] = false;
        }
      }
    }
  }
  window.requestAnimationFrame(updateStatus);
}

const triggerButtonEvent = (gamepadId: number, buttonId: number) => {
  if (buttonMapping[buttonId]) {
    console.log(`${buttonMapping[buttonId]} was pressed`);
    emitter.emit('buttonPressed', buttonMapping[buttonId]);
  }
};

const scanGamepads = () => {
  const gamepads: any = navigator.getGamepads ? navigator.getGamepads() : [];
  for (let gIdx = 0; gIdx < gamepads.length; gIdx++) {
    if (gamepads[gIdx]) {
      if (!(gamepads[gIdx].index in activeGamepads)) {
        addGamepad(gamepads[gIdx]);
      } else {
        activeGamepads[gamepads[gIdx].index] = gamepads[gIdx];
      }
    }
  }
};

export const onButtonPressed = (cb: (buttonId: number, buttonName: string) => unknown) => {
  emitter.on('buttonPressed', cb);
  return () => emitter.removeListener('buttonPressed', cb);
};
