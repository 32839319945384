import React, { useState } from 'react';
import styles from './SimpleUploader.module.scss';
import VideoPlayerPage from './VideoPlayer/VideoPlayerPage';
import VideoReviewPage from './VideoReview/VideoReviewPage';

type Props = {};

const SimpleUploader = (props: Props) => {
  const [playing, setPlaying] = useState(false);
  const [reviewing, setReviewing] = useState(false);

  const [vidFiles, setFile] = useState<{ [file: string]: null | string }>({
    movFileName: null,
    movFile: null,
    subFile: null
  });

  const onFileChange = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || !files[0]) return;
    const file = files[0]!;
    const url = URL.createObjectURL(file);
    setFile({ ...vidFiles, [type]: url, [`${type}Name`]: file.name });
  };

  const onWatch = () => {
    if (vidFiles.movFile && vidFiles.subFile) {
      setPlaying(true);
    }
  };

  const onReview = () => {
    if (vidFiles.subFile) {
      setReviewing(true);
    }
  };

  if (reviewing && vidFiles.subFile) {
    return <VideoReviewPage videoUrl={vidFiles.movFile || ''} subtitleUrl={vidFiles.subFile} />;
  }

  if (playing && vidFiles.movFile && vidFiles.subFile) {
    return (
      <VideoPlayerPage
        videoUrl={vidFiles.movFile}
        subtitleUrl={vidFiles.subFile}
        autoPlay={true}
        fileName={vidFiles.movFileName!}
      />
    );
  }

  return (
    <div className={styles.container}>
      <label className={styles.title}>Custom File Viewer</label>
      <label className={styles.fileLabel}>Movie File</label>
      <input className={styles.fileInput} type="file" accept="video/*" onChange={onFileChange('movFile')} />
      <label className={styles.fileLabel}>Subtitle File</label>
      <input className={styles.fileInput} type="file" accept=".srt" onChange={onFileChange('subFile')} />
      <div className={styles.uploadBtn} onClick={onWatch}>
        Watch
      </div>
      <div className={styles.uploadBtn} onClick={onReview}>
        Review
      </div>
    </div>
  );
};

export default SimpleUploader;
