import React, { useEffect, useRef } from 'react';
import styles from './VideoSeekHoverPreview.module.scss';
import { getTimeDivided, padZeros } from '../../../../Utils/TimeUtils';
import classNames from 'classnames';

type Props = {
  className: string;
  videoPath: string;
  seekTime: number;
  duration: number;
  position: number;
  trackWidth: number;
};

const getHoverTime = (time: number) => {
  const { hours, minutes, seconds } = getTimeDivided(time);
  return `${padZeros(hours, 2)}:${padZeros(minutes, 2)}:${padZeros(seconds, 2)}`;
};

const VideoSeekHoverPreview = (props: Props) => {
  const hoverVidRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (props.seekTime > props.duration) return;
    if (hoverVidRef.current) {
      hoverVidRef.current.currentTime = Math.max(0, props.seekTime);
    }
  }, [props.seekTime, props.duration]);

  const left = Math.max(30, Math.min(props.position, props.trackWidth - 30));
  const hoverTime = getHoverTime(props.seekTime);

  return (
    <div className={classNames(styles.hoverTime, props.className)} style={{ left }}>
      <video src={props.videoPath} className={styles.hoverVideo} ref={hoverVidRef} muted={true} />
      <div className={styles.hoverText}>{hoverTime}</div>
    </div>
  );
};

export default VideoSeekHoverPreview;
