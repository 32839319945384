import { SubtitleLine } from './VideoPlayerReducer';

export const findSubtitleByTime = (subtitles: SubtitleLine[] | null, time: number, fromSub: number = 0) => {
  if (!subtitles || subtitles.length === 0) {
    return { hidden: true, index: -1 };
  }

  for (let i = fromSub; i < subtitles.length; i++) {
    const nextSub = subtitles[i];
    const nextNextSub = subtitles[i + 1];
    if (time >= nextSub.start && (!nextNextSub || nextNextSub.start > time)) {
      // Show the subtitle a little longer than usual but not more than 2 seconds
      if (time >= nextSub.end + 2) {
        return { hidden: true, index: i };
      }

      return { hidden: false, index: i };
    }
  }

  return { hidden: true, index: -2 };
};
