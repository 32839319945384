import React from 'react';
import styles from './VolumeControls.module.scss';
import * as PlayerIcons from '../PlayerIcons';
import Slider from 'react-rangeslider';

type Props = {
  volume: number;
  onMute: (mute: boolean) => any;
  onUpdateVolume: (volume: number) => any;
};

const VolumeControls = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.volumeIcon}>
        {props.volume > 0 && <PlayerIcons.SoundOn onClick={() => props.onMute(true)} width={20} height={20} />}
        {props.volume === 0 && <PlayerIcons.SoundOff onClick={() => props.onMute(false)} width={20} height={20} />}
      </div>
      <Slider min={0} max={100} value={props.volume} step={1} onChange={props.onUpdateVolume} />
    </div>
  );
};

export default VolumeControls;
