import React, { useRef, useState } from 'react';
import styles from './VideoSeeker.module.scss';
import classNames from 'classnames';
import VideoSeekHoverPreview from './VideoSeekHoverPreview';

type Props = {
  videoPath: string;
  duration: number;
  currentTime: number;
  isPlaying: boolean | undefined;
  onChange: (time: number) => number;
};

const VideoSeeker = (props: Props) => {
  const [hoverPos, setHoverPos] = useState(0);
  const trackRef = useRef<HTMLDivElement>(null);

  const playedPct = props.currentTime / props.duration;
  const trackWidth = trackRef.current ? trackRef.current.offsetWidth : 0;
  const hoverPct = hoverPos / trackWidth;
  const thumbPos = playedPct * trackWidth;
  const seekTime = hoverPct * props.duration || 0;

  const getMousePosition = (e: MouseEvent | React.MouseEvent) => {
    if (!trackRef.current) return 0;
    return e.pageX - trackRef.current.getBoundingClientRect().left;
  };

  const setSeekPos = async (e: MouseEvent | React.MouseEvent) => {
    if (!trackRef.current) return;
    const pos = getMousePosition(e);
    setHoverPos(pos);
  };

  const onUpdate = (e: React.MouseEvent) => {
    const pos = getMousePosition(e);
    const newTime = props.duration * (pos / trackWidth);
    props.onChange(newTime);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__isPlaying]: props.isPlaying
      })}
    >
      <div className={styles.track} onMouseDown={onUpdate} onMouseMove={setSeekPos} ref={trackRef}>
        <div className={styles.trackLine} />
        <div
          className={`${styles.trackLine} ${styles.trackLine__played}`}
          style={{ transform: `scaleX(${playedPct})` }}
        />
        <div
          className={`${styles.trackLine} ${styles.trackLine__hover}`}
          style={{ transform: `scaleX(${hoverPct})` }}
        />
      </div>

      <VideoSeekHoverPreview
        className={styles.hoverPreview}
        videoPath={props.videoPath}
        seekTime={seekTime}
        trackWidth={trackWidth}
        duration={props.duration}
        position={hoverPos}
      />

      <div className={styles.thumb} style={{ transform: `translateX(${thumbPos}px)` }}>
        <div className={styles.handle} />
      </div>
    </div>
  );
};

export default VideoSeeker;
