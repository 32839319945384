import { createAction } from 'src/Utils/ReduxUtils';
import { Dispatch } from 'react';
import { parse } from 'subtitle';
import { SubtitleLine } from './VideoPlayerReducer';
import { ReducerState } from '../../../Redux/RootReducer';
import { findSubtitleByTime } from './SubtitleActionUtils';

export enum VideoPlayerActionTypes {
  CLEAR = '@VideoPlayer/CLEAR',
  ADD_SUBTITLES = '@VideoPlayer/ADD_SUBTITLES',
  UPDATE_CURRENT_SUBTITLE = '@VideoPlayer/UPDATE_CURRENT_SUBTITLE'
}

export const clear = () => createAction(VideoPlayerActionTypes.CLEAR);
export const addSubtitlesAction = (subtitles: SubtitleLine[]) =>
  createAction(VideoPlayerActionTypes.ADD_SUBTITLES, { subtitles });
export const updateSubtitleAction = (currentSub: number, subHidden: boolean) =>
  createAction(VideoPlayerActionTypes.UPDATE_CURRENT_SUBTITLE, { currentSub, subHidden });

export const addSubtitles = (srtFileUrl: string) => {
  return async (dispatch: Dispatch<any>) => {
    const srtFile = await fetch(srtFileUrl);
    const srtContents = await srtFile.text();

    // The output from parse will give times in ms but html5 videos use
    // seconds, so easier to just here
    const subtitles: SubtitleLine[] = parse(srtContents).map((sub: any, i) => {
      return {
        start: sub.start / 1000,
        end: sub.end / 1000,
        id: i + 1,
        data: JSON.parse(sub.text)
      };
    });

    if (subtitles) {
      let lastTime = 0;
      subtitles.forEach(s => {
        if (lastTime > s.start) {
          alert('Subtitle time order is wrong, please check the srt file');
        }
      });
    }

    dispatch(addSubtitlesAction(subtitles));
  };
};

export const updateSubtitle = (time: number, fromSub?: number) => {
  return async (dispatch: Dispatch<any>, getState: () => ReducerState) => {
    const { subtitles, currentSub, subHidden } = getState().videoPlayer;
    const { hidden, index } = findSubtitleByTime(subtitles, time, fromSub);
    // makes it to debug if we only send an event when something actually changed
    if (currentSub !== index || hidden !== subHidden) {
      dispatch(updateSubtitleAction(index, hidden));
    }
  };
};
