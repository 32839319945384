import React from 'react';
import './HoverWord.scss';
import classNames from 'classnames';
import { speak } from '../../../Utils/SpeechUtils';
import { addWordToPlugin, hasPlugin } from '../../../Utils/ChromeUtils';
import { isMobile } from '../../../Utils/GeneralUtils';

type Props = {
  isHovering: boolean;
  onHover: () => any;
  showSimplified: boolean;
  showPinyin: boolean;
  simplified: string;
  pinyin: string;
  definition: string;
};

function HoverWord(props: Props) {
  const addWord = (e: React.MouseEvent) => {
    e.stopPropagation();
    addWordToPlugin(props.simplified, props.pinyin, props.definition);
  };

  const defs = (props.definition || '').split(/\//g);
  const longest = Math.max(...defs.map(a => a.length), 20);

  return (
    <div
      className={classNames('HoverWord', {
        'HoverWord--notChinese': !props.definition,
        'HoverWord--hidePinyin': !props.showPinyin,
        'HoverWord--hideChinese': !props.showSimplified,
        'HoverWord--isHovering': props.isHovering
      })}
      onMouseOver={props.onHover}
      onClick={() => speak(props.simplified)}
    >
      {props.showSimplified && <div className="HoverWord__hanzi">{props.simplified}</div>}
      {props.showPinyin && <div className="HoverWord__pinyin">{props.pinyin}</div>}

      <div
        className="HoverWord__Tooltip"
        style={{
          minWidth: Math.min(450, longest * 10)
        }}
        onClick={e => e.stopPropagation()}
      >
        <div className="HoverWord__TooltipHanzi">{props.simplified}</div>
        <div className="HoverWord__TooltipPinyin">{props.pinyin}</div>
        <div className="HoverWord__TooltipDefs">
          {defs.map((d, i) => (
            <div key={i} className="HoverWord__TooltipDef">
              {d}
            </div>
          ))}
        </div>

        <div className="HoverWord__buttons">
          {!isMobile && (
            <a
              className="HoverWord__addBtn"
              target="_blank"
              style={{ marginRight: 5 }}
              rel="noopener noreferrer"
              href={`https://chinesepod.com/dictionary/english-chinese/${props.simplified}`}
            >
              Examples
            </a>
          )}
          {hasPlugin && (
            <button className="HoverWord__addBtn" onClick={addWord}>
              Save Word
            </button>
          )}

          {isMobile && (
            <a
              className="HoverWord__addBtn"
              target="_blank"
              rel="noopener noreferrer"
              href={`plecoapi://x-callback-url/s?q=${props.simplified}`}
            >
              Open in Pleco
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default HoverWord;
