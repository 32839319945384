import { VideoPlayerActions, VideoPlayerActionTypes } from './VideoPlayerTypes';

export interface SubtitleLine {
  start: number; // time in seconds
  end: number; // time in seconds
  data: {
    original: string;
    translated: string;
    words: {
      id: number;
      simplified: string;
      pinyin?: string;
      def?: string;
    }[];
  };
}

export interface VideoPlayerReducerState {
  subtitles: SubtitleLine[] | null;
  currentSub: number;
  subHidden: boolean;
  repeatingSub: number;
}

const initialState: VideoPlayerReducerState = {
  subtitles: null,
  currentSub: -1,
  subHidden: true,
  repeatingSub: -1
};

export default (state: VideoPlayerReducerState = initialState, action: VideoPlayerActions): VideoPlayerReducerState => {
  switch (action.type) {
    case VideoPlayerActionTypes.CLEAR: {
      return { ...initialState };
    }
    case VideoPlayerActionTypes.ADD_SUBTITLES: {
      const { subtitles } = action.payload;
      return { ...state, subtitles };
    }
    case VideoPlayerActionTypes.UPDATE_CURRENT_SUBTITLE: {
      const { currentSub, subHidden } = action.payload;
      return { ...state, currentSub, subHidden };
    }
    default: {
      return state;
    }
  }
};
