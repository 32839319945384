import { createStore, compose, applyMiddleware } from 'redux';
import { ReducerState } from '../Redux/RootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
  ? (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({
      // actionsBlacklist: []
    })
  : compose;

export const prepareReduxStore = ({ reducers, middlewares }: { reducers: any; middlewares?: any }) => {
  return createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));
};

export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

// @ts-ignore
export function createAction<T extends string>(type: T): Action<T>;

// @ts-ignore
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;

// @ts-ignore
export function createAction<T extends string, P>(type: T, payload?: P, extraProps: ActionExtraProps = {}) {
  return payload === undefined ? { type, payload: null, ...extraProps } : { type, payload, ...extraProps };
}

// @ts-ignore
type ActionCreator<T extends string = string> = (...args: any[]) => Action<T> | ActionWithPayload<T, any>;

// @ts-ignore
export type ActionsUnion<A extends any> = ReturnType<
  A[{ [K in keyof A]: A[K] extends ActionCreator ? K : never }[keyof A]]
>;

export type ReduxDispatch = ThunkDispatch<ReducerState, any, Action<any>>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export function useReduxState<T>(selector: (state: ReducerState) => T): T {
  return useSelector<ReducerState, T>(selector);
}
