import { createAction } from 'src/Utils/ReduxUtils';
import { VideoSettingsReducerState } from './VideoSettingsReducer';
import { Dispatch } from 'react';
import { ReducerState } from '../../../../Redux/RootReducer';
import { saveSettings } from '../../../../Utils/StorageUtils';
import { createDeck, createModel } from '../../components/AnkiDialog/AnkiUtils';

export enum VideoSettingsActionTypes {
  CLEAR = '@VideoSettings/CLEAR',
  UPDATE_SETTING = '@VideoSettings/UPDATE_SETTING'
}

export const clear = () => createAction(VideoSettingsActionTypes.CLEAR);
export const updateSettingAction = (setting: keyof VideoSettingsReducerState, value: any) =>
  createAction(VideoSettingsActionTypes.UPDATE_SETTING, { setting, value });

export const updateSetting = (setting: keyof VideoSettingsReducerState, value: any) => {
  return async (dispatch: Dispatch<any>, getState: () => ReducerState) => {
    const settings = getState().videoSettings;
    saveSettings({ ...settings, [setting]: value });
    dispatch(updateSettingAction(setting, value));
  };
};

export const setupAnki = () => {
  return async (dispatch: Dispatch<any>, getState: () => ReducerState) => {};
};
