import React from 'react';
import styles from './SubtitleViewer.module.scss';
import { useReduxState } from '../../../../Utils/ReduxUtils';
import SubtitleViewerLine from './SubtitleViewerLine';
import classNames from 'classnames';
import { Exit } from '../PlayerIcons';

type Props = {
  active: boolean;
  exit: () => unknown;

  playSub: (index: number) => unknown;
};

const SubtitleViewer = (props: Props) => {
  const { subtitles } = useReduxState(state => state.videoPlayer);

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__active]: props.active
      })}
      onMouseMove={e => e.stopPropagation()}
    >
      <div className={styles.exitButton} onClick={props.exit}>
        <Exit height={20} width={20} />
      </div>
      <div className={styles.subtitles}>
        {subtitles && subtitles.map((sub, i) => <SubtitleViewerLine subtitle={sub} playSub={() => props.playSub(i)} />)}
      </div>
    </div>
  );
};

export default SubtitleViewer;
