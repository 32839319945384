import React, { useState } from 'react';
import styles from './HeroLoader.module.scss';
import { useInterval } from '../../Hooks/useInterval';
import { HeroImage } from './HeroImage';

type Props = {};

const lines = ['Loading', '下载中', '今天你是我的英雄'];

let fullLines = lines.flatMap(line => {
  const expandedLine = [];
  for (let i = 0; i < line.length; i++) {
    expandedLine.push(line.substr(0, i + 1));
  }
  for (let i = line.length; i >= 0; i--) {
    expandedLine.push(line.substr(0, i));
  }

  return expandedLine;
});

const HeroLoader = (props: Props) => {
  const [lineNum, setLineNum] = useState(0);

  useInterval(() => {
    setLineNum((lineNum + 1) % fullLines.length);
  }, 200);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Subtitle Hero</div>
      <img alt="" className={styles.subImage} src={HeroImage} />
      <div className={styles.subtitle}>{fullLines[lineNum]}...</div>
    </div>
  );
};

export default HeroLoader;
