export const playAudio = (audioFile: string, start: number, end: number) => {
  const audio = new Audio(`${audioFile}#t=${start},${end}`);
  audio.play();
};

export const extraAudio = (audioFile: string, start: number, end: number): Promise<{ blob: Blob; url: string }> => {
  return new Promise(resolve => {
    const context = new AudioContext();
    const dest = context.createMediaStreamDestination();
    const audio = new Audio(`${audioFile}#t=${start},${end}`);
    const sound = context.createMediaElementSource(audio);
    const mediaRecorder = new (window as any).MediaRecorder(dest.stream);

    sound.connect(dest);
    mediaRecorder.start();
    audio.play();
    audio.addEventListener('pause', () => {
      mediaRecorder.stop();
    });

    const chunks: any = [];
    mediaRecorder.ondataavailable = (evt: any) => {
      // push each chunk (blobs) in an array
      chunks.push(evt.data);
    };

    mediaRecorder.onstop = (evt: any) => {
      // Make blob out of our blobs, and open it.
      const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
      resolve({ blob, url: URL.createObjectURL(blob) });
    };
  });
};

export const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onloadend = function() {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
