
const googleChineseVoice = "Google\u00A0普通话（中国大陆）";
const tingTingVoice = "Ting-Ting";

let voices: SpeechSynthesisVoice[] = [];


window.speechSynthesis.onvoiceschanged = () => {
  voices = speechSynthesis.getVoices();
};

export function getVoices() {
  return voices || [];
}


export function speak(word: string) {
  if (voices.length === 0) return;

  let voice = voices.find(a => a.voiceURI === googleChineseVoice);
  // If the user isn't using a vpn, voices will not include google
  if (!voice) {
    voices.find(a => a.voiceURI === tingTingVoice);
  }
  if (!voice) return;

  const utterance = new SpeechSynthesisUtterance(word);
  utterance.voice = voice;
  speechSynthesis.speak(utterance);
}
