import React, { ChangeEvent, useState } from 'react';
import styles from './VideoSettings.module.scss';
import classNames from 'classnames';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/themes/theme-rickiest.css';
import './button-styles-override.css';
import { installApp } from '../../../../Utils/ChromeUtils';
import { Exit } from '../PlayerIcons';
import { useReduxDispatch, useReduxState } from '../../../../Utils/ReduxUtils';
import { updateSetting } from '../../Redux/VideoSettings/VideoSettingsActions';
import { createDeck, createModel } from '../AnkiDialog/AnkiUtils';
import { useOnButtonPressed } from '../../../../Hooks/useOnButtonPressed';
import { GamePadButton } from '../../../../Utils/GamePadUtils';

type Props = {
  active: boolean;
  exit: () => unknown;
};

const Option = (props: { name: string; value: string; children: JSX.Element }) => {
  return (
    <div key={props.name} className={styles.listOption}>
      <label className={styles.optionLabel}>{props.name}</label>
      <div className={styles.optionInput}>{props.children}</div>
      <div className={styles.optionInput}>
        <span className={styles.displayValue}>{props.value}</span>
      </div>
    </div>
  );
};

const RangeOption = (props: {
  name: string;
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
}) => {
  return (
    <Option name={props.name} value={`${Math.round(props.value)}%`}>
      <input type="range" min="0" max="100" step="0.1" value={props.value} onChange={props.onChange} />
    </Option>
  );
};

const ToggleOption = (props: { name: string; value: boolean; onChange: (checked: boolean) => unknown }) => {
  return (
    <Option name={props.name} value={props.value ? 'on' : 'off'}>
      <button
        className={classNames(styles.checkbox, {
          [styles.checkbox__checked]: props.value
        })}
        onClick={() => props.onChange(!props.value)}
      />
    </Option>
  );
};

const VideoSettings = (props: Props) => {
  const [isAnkiInstalling, setAnkiInstalling] = useState(false);
  const dispatch = useReduxDispatch();
  const settings = useReduxState(state => state.videoSettings);

  const settingRadioChange = (setting: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    dispatch(updateSetting(setting as any, Number(val)));
  };

  const settingCheckboxChange = (setting: string) => (checked: boolean) => {
    dispatch(updateSetting(setting as any, checked));
  };

  const setupAnki = async () => {
    setAnkiInstalling(true);
    try {
      await createDeck('Subtitle Hero');
      await createModel('Subtitle Hero Hanzi Question');
    } catch (e) {
      console.log(e);
      setAnkiInstalling(false);
      return;
    }
    dispatch(updateSetting('isAnkiSetup', true));
  };

  useOnButtonPressed(GamePadButton.B, () => {
    dispatch(updateSetting('showTranslation', !settings.showTranslation));
  });

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__active]: props.active
      })}
      onMouseMove={e => e.stopPropagation()}
    >
      <div className={styles.exitButton} onClick={props.exit}>
        <Exit height={20} width={20} />
      </div>
      <header className={styles.mainHeader}>
        <h2 className={styles.headerText}>Settings</h2>
        <p>Install as a Desktop App.</p>
        <AwesomeButton type="primary" action={() => installApp()}>
          Install
        </AwesomeButton>
      </header>

      <header>
        <h3 className={styles.subHeaderText}>Video Settings</h3>
      </header>
      <div className={styles.list}>
        <RangeOption
          name="Bottom Black Bar"
          value={settings.blackBarHeight}
          onChange={settingRadioChange('blackBarHeight')}
        />
        <ToggleOption
          name="Show Simplified"
          value={settings.showSimplified}
          onChange={settingCheckboxChange('showSimplified')}
        />
        <ToggleOption name="Show Pinyin" value={settings.showPinyin} onChange={settingCheckboxChange('showPinyin')} />
        <ToggleOption
          name="Show Translation"
          value={settings.showTranslation}
          onChange={settingCheckboxChange('showTranslation')}
        />
      </div>

      <header>
        <h3 className={styles.subHeaderText}>Anki Settings</h3>
      </header>
      <div className={styles.list}>
        <p>Create Anki Cards - Requires Anki Connect to be installed and anki to be running</p>
        {!settings.isAnkiSetup && (
          <>
            {!isAnkiInstalling && (
              <>
                <p>
                  This will create a new deck called "Subtitle Hero" and a new model called "Subtitle Hero Hanzi
                  Question"
                </p>
                <AwesomeButton type="primary" action={setupAnki}>
                  Setup
                </AwesomeButton>
              </>
            )}
            {isAnkiInstalling && <p>Setting up anki now</p>}
          </>
        )}

        {settings.isAnkiSetup && <p>Anki is already setup</p>}
      </div>
    </div>
  );
};

export default VideoSettings;
