

export function toggleFullScreen() {
  const isFullScreen = !!(window.document as any).fullscreenElement;
  if (!isFullScreen) {
    // noinspection JSIgnoredPromiseFromCall
    document.documentElement.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
}